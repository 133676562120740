<template>
  <div></div>
</template>

<script>
import { verifySocialProviderAuth } from "@/api/auth";
export default {
  name: "Five9Login",
  created() {
    if (this.$route.hash) {
      let self = this;
      verifySocialProviderAuth("five9", this.$route.hash).catch(data => {
        this.$router.push({
          name: "Login",
          query: { redirectUrl: data["redirect"] }
        });
        self.$message({
          type: "error",
          message: data.error ? data.error : __("Five9 User not found")
        });
      });
    }
  }
};
</script>

<style scoped></style>
